// Part of the theme that does not import react so we can use it in the backend
// with the file `frontend_theme_registry_no_react.ts`

import {FrontendUserAuthMethodType, FrontendUserDataContentType} from '@shared/dynamo_model';
import {FrontendTheme} from '@shared/frontends/frontend_theme_model';
import {textColor} from '@shared/frontends/frontend_theme_utils';
import {DeepPartial} from '@shared/lib/type_utils';

export const Colors = {
  SuccessLight: '#2dbf4f',
  SuccessNormal: '#28ab47',
  SuccessDark: '#208537',
  White: '#fff',
  Black: '#000',
  DarkGray: '#54565b',
  Gray: '#838a9c',
  LightGray: '#e9ebee',
  DarkBlue: '#010b21',
  DarkBlue2: '#03112f',
  DarkBlue3: '#243277',
  LightBlue: '#19bdf7',
  Blue: '#275ad5',
  Blue2: '#4466f3',
  Purple: '#903cdf',
  Pink: '#fe106e',
  DarkPink: '#9b1153',
  DarkPink2: '#1a0c2a',
};

export const HomePageColors = {
  DarkDarkBlue: '#030214',
  DarkBlue: '#070328',
  Blue: '#056F95',
  Pink: '#DF00A0',
  PinkDisabled: '#7d3468',
  Purple: '#9701E6',
  PurpleDisabled: '#54266c',
};

export const MaterialColors = {
  Blue: '#2962FF',
  Purple: '#AA00FF',
  Teal: '#00BFA5',
  Yellow: '#FFD600',
  Orange: '#FF6D00',
};

export const FontWeight = {
  SemiLight: 300,
  Regular: 400,
  SemiBold: 500,
};

export const Theme = {
  base: {
    fontFamily: 'Inter, sans serif',
  },
};

export const Sizes = {
  Content: 1920, // Update index.html when changing this value
  ContentPadding: 48,
};

export const baseTheme = {
  main: {
    backgroundColor: HomePageColors.DarkBlue,
    fontFamily: Theme.base.fontFamily,
    textColor: '#ffffff',
  },
  button: {
    backgroundActive: '#fe106e',
    backgroundDisabled: '#ac5a7b',
    backgroundLoading: '#ac5a7b',
    backgroundHover: '#9b1153',
    focusBorderColor: '#3F51B5',
    loaderColor: '#ffffff44',
    borderRadius: 6,
    fontSize: 14,
  },
  link: {
    ...textColor('#fe106e'),
    loaderColor: '#ffffff44',
  },
  input: {
    paddingRight: 8,
    paddingLeft: 8,
    backgroundColor: '#00000033',
    backgroundColorHover: '#00000055',
    backgroundColorFocus: '#00000077',
    borderColor: '#ffffff55',
    textColor: '#ffffff',
    borderRadius: 0,
    borderWidth: 2,
    focusBorderColor: '#fe106e',
    focusBorderWidth: 2,
    focusOutlineColor: undefined,
    focusOutlineWidth: 0,
    fontSize: 16,
  },
  textarea: {
    paddingTop: 4,
    paddingRight: 8,
    paddingBottom: 4,
    paddingLeft: 8,
    backgroundColor: '#00000033',
    backgroundColorHover: '#00000055',
    backgroundColorFocus: '#00000077',
    borderColor: '#ffffff55',
    textColor: '#ffffff',
    borderRadius: 0,
    borderWidth: 2,
    focusBorderColor: '#fe106e',
    focusBorderWidth: 2,
    focusOutlineColor: undefined,
    focusOutlineWidth: 0,
    fontSize: 16,
  },
  auth: {
    postLoginPage: '/demo/home',
    userAuthTypes: [FrontendUserAuthMethodType.MagicLink, FrontendUserAuthMethodType.MfaCode],
    userDataContentType: FrontendUserDataContentType.Onescale,
  },
} satisfies DeepPartial<FrontendTheme>;
