import {FrontendUserDataContentType} from '@shared/dynamo_model';

import {
  createTypedSessionDataStore,
  TypedUserSessionItem,
} from '@shared-frontend/lib/session_store';

const typedDataStore = createTypedSessionDataStore<FrontendUserDataContentType.Onescale>();

export function useOnescaleSession():
  | TypedUserSessionItem<FrontendUserDataContentType.Onescale>
  | undefined {
  const session = typedDataStore.useData();
  return 'sessionId' in session ? session : undefined;
}
