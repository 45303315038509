import {Fragment, useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';

import {OnescaleApi} from '@shared/api/definitions/public_api/www_api';
import {DomainName, WebEventsDataSourceDatalakeElementItem} from '@shared/dynamo_model';
import {generateLayoutForDataSource} from '@shared/frontends/datalake_element_layout';
import {generateAnalyticsScript} from '@shared/lib/analytics/snippet';

import {Button} from '@shared-frontend/components/core/button';
import {CenteredLine} from '@shared-frontend/components/core/fragments';
import {Input} from '@shared-frontend/components/core/input';
import {Spacing} from '@shared-frontend/components/core/spacing';
import {Custom, EmptyFragment} from '@shared-frontend/lib/react';

import {
  getElements,
  refreshDatalake,
  useDatalakeId,
} from '@src/components/demo/datalake/demo_store';
import {Form, FormPropsBase, Label} from '@src/components/demo/datalake/toolbox/forms/common/form';
import {useDeleteElementHandler} from '@src/components/demo/datalake/toolbox/forms/use_delete_element_handler';
import {apiCall} from '@src/lib/network';
import {useOnescaleSession} from '@src/lib/stores';

interface WebEventsDataSourceFormProps extends FormPropsBase {
  update?: WebEventsDataSourceDatalakeElementItem;
}

export const WebEventsDataSourceForm: Custom<WebEventsDataSourceFormProps, 'div'> = props => {
  const {update, onDone, ...rest} = props;
  const datalakeId = useDatalakeId();
  const session = useOnescaleSession();

  const [domainNames, setDomainNames] = useState(
    update?.params.domainNames ?? (update ? [update.params.domainName] : undefined)
  );
  const syncDomainNames = useCallback(
    (val: string) => setDomainNames(val.split(',') as DomainName[]),
    []
  );

  useEffect(() => {
    if (!update) {
      return;
    }
    setDomainNames(update.params.domainNames);
  }, [update]);

  const handleClick = useCallback(async () => {
    if (domainNames === undefined || session === undefined) {
      return;
    }
    if (update !== undefined) {
      // eslint-disable-next-line no-console
      console.log('Updating', update);
      await new Promise(resolve => {
        setTimeout(resolve, 1000);
      });
    } else {
      const {items} = getElements();
      await apiCall(OnescaleApi, '/create-data-lake-web-event-data-source', {
        id: datalakeId,
        domainNames,
        layout: generateLayoutForDataSource(items),
      });
      await refreshDatalake();
    }
    onDone();
  }, [domainNames, session, update, onDone, datalakeId]);

  const handleDelete = useDeleteElementHandler(update?.elementId);

  return (
    <Form {...rest}>
      <Label>Domaines du site Web</Label>
      <Input
        theme="demo"
        value={domainNames?.join(',') ?? ''}
        syncState={syncDomainNames}
        autoFocus
      />
      {update !== undefined ? (
        <Fragment>
          <Spacing height={16} />
          <Label>Snippet:</Label>
          <SnippetUrl>{`<script>${generateAnalyticsScript()}<\\/script>`}</SnippetUrl>
        </Fragment>
      ) : (
        EmptyFragment
      )}
      <Spacing height={8} />
      <CenteredLine>
        {update === undefined ? (
          <Button onClickAsync={handleClick}>VALIDER</Button>
        ) : (
          <Button onClickAsync={handleDelete}>SUPPRIMER</Button>
        )}
      </CenteredLine>
    </Form>
  );
};
WebEventsDataSourceForm.displayName = 'WebEventsDataSourceForm';

const Snippet = styled.pre`
  font-family: monospace;
  background: #ffffff11;
  padding: 8px;
  border-radius: 8px;
  overflow: auto;
  user-select: all;
`;

const SnippetUrl = styled(Snippet)`
  height: 32px;
`;
