import {useCallback, useEffect, useState} from 'react';

import {
  DatalakeElementItemId,
  DatalakeElementType,
  DatalakeElementTypeGroup,
  DatalakeOutputColumnType,
  SumsAggregationDatalakeElementItem,
  SumsAggregationDatalakeElementPayload,
} from '@shared/dynamo_model';
import {generateLayoutForNonDataSource} from '@shared/frontends/datalake_element_layout';
import {uidUnsafe} from '@shared/lib/rand';

import {Button} from '@shared-frontend/components/core/button';
import {CenteredLine} from '@shared-frontend/components/core/fragments';
import {Spacing} from '@shared-frontend/components/core/spacing';
import {notifyError} from '@shared-frontend/lib/notification';
import {Custom} from '@shared-frontend/lib/react';

import {
  getElements,
  pushElementsSnaphsot,
  useDatalakeId,
} from '@src/components/demo/datalake/demo_store';
import {Form, FormPropsBase} from '@src/components/demo/datalake/toolbox/forms/common/form';
import {
  columnIs,
  TableAndFieldSelector,
  TableFieldChangeHandler,
} from '@src/components/demo/datalake/toolbox/forms/common/table_and_field_selector';
import {useDeleteElementHandler} from '@src/components/demo/datalake/toolbox/forms/use_delete_element_handler';
import {useOnescaleSession} from '@src/lib/stores';

interface SumsAggregationFormProps extends FormPropsBase {
  update?: SumsAggregationDatalakeElementItem;
}

export const SumsAggregationForm: Custom<SumsAggregationFormProps, 'div'> = props => {
  const {update, onDone, ...rest} = props;
  const datalakeId = useDatalakeId();
  const session = useOnescaleSession();

  const [{tableId, joinColumnIndex, sumColumnIndex}, setSource] = useState<{
    tableId?: DatalakeElementItemId;
    joinColumnIndex?: number;
    sumColumnIndex?: number;
  }>({
    tableId: update?.params.source,
    joinColumnIndex: update?.params.sourcePrimaryKeyColumn,
    sumColumnIndex: update?.params.sourceColumn,
  });

  useEffect(() => {
    setSource({
      tableId: update?.params.source,
      joinColumnIndex: update?.params.sourcePrimaryKeyColumn,
      sumColumnIndex: update?.params.sourceColumn,
    });
  }, [update]);

  const handleChange = useCallback<TableFieldChangeHandler>(
    (tableId, [joinColumnIndex, sumColumnIndex]) =>
      setSource({tableId, joinColumnIndex, sumColumnIndex}),
    []
  );

  const handleClick = useCallback(() => {
    if (
      update !== undefined ||
      tableId === undefined ||
      joinColumnIndex === undefined ||
      !session
    ) {
      return;
    }
    const {items} = getElements();
    const sourceTable = items[tableId];
    if (sourceTable === undefined || sumColumnIndex === undefined) {
      return;
    }
    const joinColumn = sourceTable.outputs.columns[joinColumnIndex];
    if (joinColumn === undefined) {
      return;
    }
    const sumColumn = sourceTable.outputs.columns[sumColumnIndex];
    const outputs = [
      joinColumn,
      {name: `${joinColumn.name}_count`, type: DatalakeOutputColumnType.Number},
    ];
    if (sumColumn !== undefined) {
      outputs.push({
        name: `${sumColumn.name}_sum_by_${joinColumn.name}`,
        type: DatalakeOutputColumnType.Number,
      });
    }
    const newElementId = uidUnsafe() as DatalakeElementItemId;
    const params: SumsAggregationDatalakeElementPayload = {
      group: DatalakeElementTypeGroup.Aggregation,
      source: tableId,
      sourcePrimaryKeyColumn: joinColumnIndex,
      sourceColumn: sumColumnIndex,
    };
    const sumsAggregation: SumsAggregationDatalakeElementItem = {
      elementId: newElementId,
      datalakeId,
      accountId: session.userData.accountId,
      type: DatalakeElementType.SumsAggregation,
      params,
      outputs: {columns: outputs},
      layout: generateLayoutForNonDataSource(params, items),
    };
    pushElementsSnaphsot({...items, [newElementId]: sumsAggregation})
      .catch(notifyError)
      .finally(onDone);
  }, [datalakeId, joinColumnIndex, onDone, session, sumColumnIndex, tableId, update]);

  const handleDelete = useDeleteElementHandler(update?.elementId);

  return (
    <Form {...rest}>
      <TableAndFieldSelector
        tableId={tableId}
        tableLabel="Table à sélectionner"
        fields={[
          {label: 'Champs à grouper', index: joinColumnIndex},
          {label: 'Champs à sommer', index: sumColumnIndex},
        ]}
        filterColumn={columnIs(DatalakeOutputColumnType.Number)}
        onChange={handleChange}
      />
      <Spacing height={8} />
      <CenteredLine>
        {update === undefined ? (
          <Button onClick={handleClick}>VALIDER</Button>
        ) : (
          <Button onClickAsync={handleDelete}>SUPPRIMER</Button>
        )}
      </CenteredLine>
    </Form>
  );
};
SumsAggregationForm.displayName = 'SumsAggregationForm';
